import React from "react";
import { Link } from "react-router-dom";
import { Container, Typography, Box, IconButton, Grid } from "@mui/material";
import SocialMediaGrid from "./SocialMediaGrid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faSquareWhatsapp } from "@fortawesome/free-brands-svg-icons";

import { faPhoneSquare } from "@fortawesome/free-solid-svg-icons";

const Footer = (props) => {
    const sxLogo = { mb: 2 };

    const getNavigationItems = () => {
        let navigation = [];

        props.navigationItems.forEach((navigationItem) => {
            if (navigationItem.footer) {
                navigation.push(
                    <Typography
                        variant="body1"
                        // align="center"
                        component={Link}
                        to={navigationItem.path}
                        sx={props.sx.link ? { margin: "0", ...props.sx.link } : props.sx.link}
                    >
                        {navigationItem.label}
                    </Typography>
                );
            }
        });

        return navigation;
    };

    const getPhoneNumberGrid = () => {
        let phoneNumbersLength = props.phoneNumbers ? props.phoneNumbers.length : 0;

        if (phoneNumbersLength > 0 && phoneNumbersLength < 5) {
            let xs = 12,
                sm = phoneNumbersLength === 4 ? 6 : 12 / phoneNumbersLength,
                md = 12 / phoneNumbersLength;

            return (
                <Grid container justifyContent="center" alignItems="center" sx={{ mb: 4 }}>
                    {props.phoneNumbers.map((phoneNumber, index) => {
                        return (
                            <Grid
                                item
                                key={index}
                                xs={xs}
                                sm={sm}
                                md={md}
                                sx={{ mb: 2, display: "flex", justifyContent: "center", alignItems: "center" }}
                            >
                                <Typography color={props.phoneNumberColor}>
                                    <IconButton
                                        color="inherit"
                                        component={Link}
                                        to={"https://wa.me/" + phoneNumber}
                                        target="_blank"
                                        disableRipple
                                        disableTouchRipple
                                    >
                                        <FontAwesomeIcon size="lg" icon={faSquareWhatsapp} />
                                        <span style={{ marginLeft: "5px" }}>{phoneNumber}</span>
                                    </IconButton>
                                    {/* <FontAwesomeIcon
                                        size={props.iconSize}
                                        icon={faPhoneSquare}
                                        style={{ marginRight: "5px" }}
                                    />{" "}
                                    {phoneNumber} */}
                                </Typography>
                            </Grid>
                        );
                    })}
                </Grid>
            );
        } else return null;
    };

    return (
        <Box component="footer" sx={props.sxFooter}>
            <Container maxWidth="md">
                {getPhoneNumberGrid()}
                <Grid container>
                    <Grid item xs={12} md={6} sx={{ justifyContent: "center", color: "white" }}>
                        <Grid container justifyContent="center">
                            <Box sx={props.sx.logo ? { ...props.sx.logo, ...sxLogo } : sxLogo}>
                                <img
                                    src={props.logo}
                                    alt="Ozone disinfection and sterilisation"
                                    style={{ width: "100%", height: "100%" }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} sx={props.sxLinkContainer}>
                        <Grid container direction="column" justifyContent="center" alignItems="center">
                            {getNavigationItems()}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center">
                    <Box sx={{ mb: 2, mt: 2 }}>
                        <SocialMediaGrid
                            urls={props.socialMediaLinks}
                            sx={{ icons: { color: props.socialMediaIconColor } }}
                            iconSize={props.socialMediaIconSize}
                        />
                    </Box>
                </Grid>

                <Typography align="center" sx={props.sx.backLinkContainer ? props.sx.backLinkContainer : {}}>
                    © {props.copyright}. Web Design and Hosting by{" "}
                    <a
                        href="https://agileigniter.com"
                        style={{
                            color: "inherit",
                        }}
                    >
                        AgileIgniter
                    </a>
                </Typography>
            </Container>
        </Box>
    );
};

Footer.defaultProps = {
    sxFooter: {
        backgroundColor: "primary.main",
        padding: "20px",
        marginTop: "50px",
    },
    sxLogoContainer: {},
    sxLinkContainer: {
        display: "flex",
        justifyContent: "flex-end",
    },
    sxLink: {
        color: "white",
        textDecoration: "none",
    },
};

export default Footer;
