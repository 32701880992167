import React from "react";
import { Container, Typography, CircularProgress, Box } from "@mui/material";

function Spinner(props) {
    return (
        <Container
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "80vh", // Set the container height to full viewport height
            }}
        >
            <div
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography color="primary" variant="h6" sx={{ mb: 2 }}>
                    Loading
                </Typography>
                <Box align="center" sx={{ mt: 8 }}>
                    <CircularProgress size={30} />
                </Box>
            </div>
        </Container>
    );
}

export default Spinner;
