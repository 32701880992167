import React from "react";
import { Link } from "react-router-dom";
import { Grid, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSquareFacebook,
    faSquareTwitter,
    faSquarePinterest,
    faSquareInstagram,
    faSquareYoutube,
    faLinkedin,
    faSquareTumblr,
    faSquareWhatsapp,
    faSquareGooglePlus,
} from "@fortawesome/free-brands-svg-icons";

import { faShieldDog } from "@fortawesome/free-solid-svg-icons";

function SocialMediaGrid(props) {
    return (
        <Grid container justifyContent="center" alignItems="center">
            {props.urls.facebook && (
                <Grid item container justifyContent="center" alignItems="center" xs={4} sm={4} md={1}>
                    <IconButton component={Link} to={props.urls.facebook} target="_blank" sx={props.sx.icons}>
                        <FontAwesomeIcon size={props.iconSize} icon={faSquareFacebook} />
                    </IconButton>
                </Grid>
            )}
            {props.urls.twitter && (
                <Grid item container justifyContent="center" alignItems="center" xs={4} sm={4} md={1}>
                    <IconButton component={Link} to={props.urls.twitter} target="_blank" sx={props.sx.icons}>
                        <FontAwesomeIcon size={props.iconSize} icon={faSquareTwitter} />
                    </IconButton>
                </Grid>
            )}
            {props.urls.printerest && (
                <Grid item container justifyContent="center" alignItems="center" xs={4} sm={4} md={1}>
                    <IconButton component={Link} to={props.urls.printerest} target="_blank" sx={props.sx.icons}>
                        <FontAwesomeIcon size={props.iconSize} icon={faSquarePinterest} />
                    </IconButton>
                </Grid>
            )}
            {props.urls.instagram && (
                <Grid item container justifyContent="center" alignItems="center" xs={4} sm={4} md={1}>
                    <IconButton component={Link} to={props.urls.instagram} target="_blank" sx={props.sx.icons}>
                        <FontAwesomeIcon size={props.iconSize} icon={faSquareInstagram} />
                    </IconButton>
                </Grid>
            )}
            {props.urls.youtube && (
                <Grid item container justifyContent="center" alignItems="center" xs={4} sm={4} md={1}>
                    <IconButton component={Link} to={props.urls.youtube} target="_blank" sx={props.sx.icons}>
                        <FontAwesomeIcon size={props.iconSize} icon={faSquareYoutube} />
                    </IconButton>
                </Grid>
            )}
            {props.urls.linkedin && (
                <Grid item container justifyContent="center" alignItems="center" xs={4} sm={4} md={1}>
                    <IconButton component={Link} to={props.urls.linkedin} target="_blank" sx={props.sx.icons}>
                        <FontAwesomeIcon size={props.iconSize} icon={faLinkedin} />
                    </IconButton>
                </Grid>
            )}
            {props.urls.tumblr && (
                <Grid item container justifyContent="center" alignItems="center" xs={4} sm={4} md={1}>
                    <IconButton component={Link} to={props.urls.tumblr} target="_blank" sx={props.sx.icons}>
                        <FontAwesomeIcon size={props.iconSize} icon={faSquareTumblr} />
                    </IconButton>
                </Grid>
            )}
            {props.urls.whatsapp && (
                <Grid item container justifyContent="center" alignItems="center" xs={4} sm={4} md={1}>
                    <IconButton component={Link} to={props.urls.whatsapp} target="_blank" sx={props.sx.icons}>
                        <FontAwesomeIcon size={props.iconSize} icon={faSquareWhatsapp} />
                    </IconButton>
                </Grid>
            )}
            {props.urls.google && (
                <Grid item container justifyContent="center" alignItems="center" xs={4} sm={4} md={1}>
                    <IconButton component={Link} to={props.urls.google} target="_blank" sx={props.sx.icons}>
                        <FontAwesomeIcon size={props.iconSize} icon={faSquareGooglePlus} />
                    </IconButton>
                </Grid>
            )}
            {props.urls.bark && (
                <Grid item container justifyContent="center" alignItems="center" xs={4} sm={4} md={1}>
                    <IconButton component={Link} to={props.urls.bark} target="_blank" sx={props.sx.icons}>
                        <FontAwesomeIcon size={props.iconSize} icon={faShieldDog} />
                    </IconButton>
                </Grid>
            )}
        </Grid>
    );
}

SocialMediaGrid.defaultProps = {
    urls: {},
    sx: { icons: { color: "white" } },
    iconSize: "md",
};

export default SocialMediaGrid;
