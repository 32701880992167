import React, { createContext, useState, useEffect } from "react";

export const Context = createContext();

const localStorageName = "o3zonesolutions";

export const ContextProvider = ({ children }) => {
    const [contextData, setContextData] = useState({});

    useEffect(() => {
        // Retrieve data from local storage on component mount
        const savedData = localStorage.getItem(localStorageName);
        if (savedData != null) {
            setContextData(JSON.parse(savedData));
        }
    }, []);

    useEffect(() => {
        // Save data to local storage whenever the contextData changes
        localStorage.setItem(localStorageName, JSON.stringify(contextData));
    }, [contextData]);

    // Update context data function
    const updateContextData = (updatedData) => {
        setContextData(updatedData);
    };

    return <Context.Provider value={{ contextData, updateContextData }}>{children}</Context.Provider>;
};
