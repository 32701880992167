import React, { lazy, Suspense } from "react";

import { Route, Routes } from "react-router-dom";

import "./App.css";

import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import theme from "./theme";

import Header from "./components/navigation/Header";
import Footer from "./components/navigation/Footer";
import Spinner from "./components/Spinner";

import SiteLogo from "./assets/o3zone solution logo.png";
import SecondarySiteLogo from "./assets/ozone disinfection.png";
import BannerCoverImage from "./assets/white-clouds-with-light-blue-sky.png";

import navigationItems from "./configs/navigationItems";

const BannerPhotoCover = lazy(() => import("./components/BannerPhotoCover"));

const socialMediaLinks = {
    facebook: "https://www.facebook.com/Ozone-Solution-102102291432637",
    instagram: "https://www.instagram.com/viglaszlo69/",
    youtube: "https://www.youtube.com/watch?v=4zGSVEAVNEg&t=11s",
    whatsapp: "https://wa.me/07756735944",
    google: "https://www.google.com/search?q=Ozone+Solution+Hove",
    bark: "https://www.bark.com/en/gb/company/ozone-solution/yyzG3/",
};

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Header
                navigationItems={navigationItems}
                logo={SiteLogo}
                secondaryLogo={SecondarySiteLogo}
                socialMediaIconColor="white"
                mobileMenuIconColor="white"
                socialMediaIconSize="md"
                socialMediaLinks={socialMediaLinks}
                sx={{
                    container: {
                        marginTop: "20px",
                        marginBottom: "20px",
                    },
                    logo: {
                        width: "125px",
                    },
                    link: { mr: 2, color: "white", textDecoration: "none" },
                }}
            />

            <Routes>
                {navigationItems.map((navigationItem, index) => {
                    if (navigationItem.path && navigationItem.element) {
                        const Page = navigationItem.element;

                        return (
                            <Route
                                exact={index === 0}
                                path={navigationItem.path}
                                key={navigationItem.path}
                                element={
                                    <Suspense fallback={<Spinner />}>
                                        <Page bannerCoverImage={BannerCoverImage} bannerPhotoCover={BannerPhotoCover} />
                                    </Suspense>
                                }
                            />
                        );
                    }

                    return null;
                })}

                {navigationItems.map((navigationItem) => {
                    if (navigationItem.subItems && navigationItem.subItems.length > 0) {
                        return navigationItem.subItems.map((subItem) => {
                            const SubPage = subItem.element;

                            return (
                                <Route
                                    path={subItem.path}
                                    key={subItem.path}
                                    element={
                                        <Suspense fallback={<Spinner />}>
                                            <SubPage
                                                bannerCoverImage={BannerCoverImage}
                                                bannerPhotoCover={BannerPhotoCover}
                                            />
                                        </Suspense>
                                    }
                                />
                            );
                        });
                    }

                    return null;
                })}
            </Routes>

            <Footer
                navigationItems={navigationItems}
                logo={SiteLogo}
                socialMediaLinks={socialMediaLinks}
                socialMediaIconColor="white"
                socialMediaIconSize="md"
                phoneNumberColor="white"
                phoneNumbers={["07756947863", "07756735944"]}
                copyright={`2020 - ${new Date().getFullYear()} Ozone Solution`}
                sx={{
                    backLinkContainer: {
                        color: "white",
                    },
                    backLink: {
                        color: "inherit",
                        textDecoration: "none",
                    },
                    logo: {
                        width: "250px",
                    },
                    link: { mr: 2, color: "white", textDecoration: "none" },
                }}
            />
        </ThemeProvider>
    );
}

export default App;
