import React, { lazy, Suspense } from "react";

// Pages
const HomePage = lazy(() => import("../pages/HomePage"));
const ContactUsPage = lazy(() => import("../pages/ContactUsPage"));
const PricesPage = lazy(() => import("../pages/PricesPage"));

const SterilisationServicesPage = lazy(() => import("../pages/SterilisationServicesPage"));
const SterilisationAboutOzonePage = lazy(() => import("../pages/SterilisationAboutOzonePage"));
const SterilisationGalleryPage = lazy(() => import("../pages/SterilisationGalleryPage"));

const CleaningServicesPage = lazy(() => import("../pages/CleaningServicesPage"));
const CleaningServicesEndOfTenancyPage = lazy(() => import("../pages/CleaningServicesEndOfTenancyPage"));
const CleaningServicesDomesticPage = lazy(() => import("../pages/CleaningServicesDomesticPage"));
const CleaningServicesCommercialPage = lazy(() => import("../pages/CleaningServicesCommercialPage"));
const CleaningServicesGalleryPage = lazy(() => import("../pages/CleaningServicesGalleryPage"));

const CarpetCleaningPage = lazy(() => import("../pages/CarpetCleaningPage"));
const CarpetCleaningGalleryPage = lazy(() => import("../pages/CarpetCleaningGalleryPage"));

const PressureWashingPage = lazy(() => import("../pages/PressureWashingPage"));
const PressureWashingGalleryPage = lazy(() => import("../pages/PressureWashingGalleryPage"));

const GutterCleaningPage = lazy(() => import("../pages/GutterCleaningPage"));
const GutterCleaningGalleryPage = lazy(() => import("../pages/GutterCleaningGalleryPage"));

const navigationItems = [
    {
        label: "Home",
        path: "/",
        element: HomePage,
        header: true,
        footer: true,
    },
    {
        label: "Pressure Washing",
        path: "/sussex-pressure-washing",
        element: PressureWashingPage,
        header: true,
        footer: true,
        subItems: [],
    },
    {
        label: "Carpet Cleaning",
        path: "/sussex-carpet-cleaning",
        element: CarpetCleaningPage,
        header: true,
        footer: true,
        subItems: [],
    },
    {
        label: "Gutter Cleaning",
        path: "/sussex-gutter-cleaning",
        element: GutterCleaningPage,
        header: true,
        footer: true,
        subItems: [],
    },
    {
        label: "Cleaning Services",
        path: "/sussex-cleaning-services",
        element: CleaningServicesPage,
        header: true,
        footer: true,
        subItems: [],
    },
    {
        label: "Sterilisation Services",
        path: "/sussex-sterilisation-services",
        element: SterilisationServicesPage,
        header: true,
        footer: true,
    },
    {
        label: "Ozone, the Sterilisation Gas",
        path: "/ozone-the-sterilisation-gas",
        element: SterilisationAboutOzonePage,
        header: false,
        footer: false,
    },
    {
        label: "Sterilisation Services Gallery",
        path: "/sussex-sterilisation-services-gallery",
        element: SterilisationGalleryPage,
        header: false,
        footer: false,
    },

    {
        label: "Cleaning Services",
        path: "/sussex-end-of-tenancy-cleaning",
        element: CleaningServicesEndOfTenancyPage,
        header: false,
        footer: false,
        subItems: [],
    },
    {
        label: "Cleaning Services Domestic",
        path: "/sussex-domestic-cleaning-services",
        element: CleaningServicesDomesticPage,
        header: false,
        footer: false,
        subItems: [],
    },
    {
        label: "Cleaning Services Commercial",
        path: "/sussex-commercial-cleaning-services",
        element: CleaningServicesCommercialPage,
        header: false,
        footer: false,
        subItems: [],
    },
    {
        label: "Cleaning Services Gallery",
        path: "/sussex-cleaning-services-gallery",
        element: CleaningServicesGalleryPage,
        header: false,
        footer: false,
        subItems: [],
    },

    {
        label: "Carpet Cleaning Gallery",
        path: "/sussex-carpet-cleaning-gallery",
        element: CarpetCleaningGalleryPage,
        header: false,
        footer: false,
        subItems: [],
    },

    {
        label: "Pressure Washing Gallery",
        path: "/sussex-pressure-washing-gallery",
        element: PressureWashingGalleryPage,
        header: false,
        footer: false,
        subItems: [],
    },
    {
        label: "Gutter Cleaning Gallery",
        path: "/sussex-gutter-cleaning-gallery",
        element: GutterCleaningGalleryPage,
        header: false,
        footer: false,
        subItems: [],
    },
    {
        label: "Prices",
        path: "/prices",
        element: PricesPage,
        header: true,
        footer: true,
    },
    {
        label: "Contact Us",
        path: "/contaxct-us",
        element: ContactUsPage,
        header: true,
        footer: true,
    },
];

export default navigationItems;
